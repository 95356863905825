export default [
  {
    title: 'Content',
    icon: 'BookIcon',
    children: [
      {
        title: 'Learning Content',
        route: 'apps-chapters-list',
        icon: 'BookOpenIcon'
      },
      {
        title: 'Videos',
        route: 'apps-videos-list',
        icon: 'FilmIcon'
      },
      {
        title: 'Articles',
        icon: 'FileIcon',
        route: 'apps-articles-list'
      }
    ]
  },
  {
    title: 'Users',
    icon: 'UserIcon',
    children: [
      {
        title: 'Students',
        route: 'apps-users-list',
        action: 'view',
        resource: 'content',
      },
      {
        title: 'Teachers',
        route: 'apps-teachers-list',
      },
    ],
  },
  {
    title: 'Chat',
    icon: 'MessageSquareIcon',
    children: [
      {
        title: 'Chats',
        route: 'apps-chat',
        icon: 'UserIcon'
      },
      {
        title: 'Forums',
        route: 'apps-forums',
        icon: 'UsersIcon'
      }
    ]
  },
  {
    title: 'Logs',
    route: 'apps-logs-list',
    icon: 'FileTextIcon'
  },
]
