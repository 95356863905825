export default [
  {
    title: 'Dashboard',
    route: 'teacher-dashboard',
    icon: 'HomeIcon',
    // meta: {
    action: 'view',
    resource: 'content',
    // },
  },
]
